@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter__regular.ttf');
}

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/archivo__regular.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/ibm__plex__sans.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('./assets/fonts/ibm__plex__sans__bold.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 16px;
}

:root {
  --font-title: 'IBM Plex Sans', sans-serif;
  --font-title-bold: 'IBM Plex Sans Bold', sans-serif;
  --font-text: 'Inter', sans-serif;

  --white: #fff;
  --gray: #484848;
  --light-gray: #5d5e7a;
  --green: #2fa76a;
  --light-green: #2bd681;
}

@media (max-width: 1024px) {
  * {
    font-size: 15.5px;
  }
}

@media (max-width: 768px) {
  * {
    font-size: 15px;
  }
}

@media (max-width: 425px) {
  * {
    font-size: 14.5px;
  }
}

@media (max-width: 375px) {
  * {
    font-size: 12px;
  }

  h1,
  h2 {
    line-height: 38px !important;
  }
}
