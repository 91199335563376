footer {
  width: 100%;
  height: 100%;

  background-color: var(--gray);

  padding: 4.5rem 2rem 2rem 2rem;
}

footer .property {
  border-top: 1px solid #636363;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 0 0 0;
  margin-top: 4rem;
}

footer .property h3 {
  font-family: var(--font-title);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  color: var(--white);
}

footer .container {
  max-width: 1080px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  padding: 0 2rem;

  margin: 0 auto;
}

footer .container .contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .container .contact h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 600;

  color: var(--white);
  letter-spacing: -0.05rem;
  margin-bottom: 3rem;
}

footer .container .contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: 1.6rem;

  width: 100%;

  padding: 0 2rem;
}

footer .container .contact form input {
  max-width: 330px;
  width: 100%;
  height: 62px;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
  border: none;

  font-family: var(--font-text);
  font-weight: 300;
  font-size: 1.25rem;
}

footer .container .contact form textarea {
  border-radius: 4px;
  padding: 1rem;
  outline: none;
  border: none;

  resize: none;

  width: 100%;
  max-width: 330px;
  min-height: 180px;
  max-height: 200px;

  font-family: var(--font-text);
  font-weight: 300;
  font-size: 1.25rem;
}

footer .container .contact form button {
  background-color: var(--green);
  color: var(--white);

  width: 100%;
  max-width: 330px;
  height: 62px;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
  border: none;

  font-size: 1.25rem;
  font-weight: 300;

  cursor: pointer;
}

footer .container .contact form button:hover {
  transition: 0.5s all ease-out;

  background-color: #1d9356;
}

footer .container .contact form .form__return {
  max-width: 330px;
  border-radius: 4px;
  background-color: aliceblue;
  padding: 12px;
}

footer .container .contact form .form__return h4 {
  font-size: 16px;

  color: #fff;
}

footer .container .contact form .form__return h4 a {
  color: #fff;
}

footer .container .find-us {
  justify-content: space-between;
  display: flex;
  column-gap: 3rem;
  row-gap: 3rem;
  flex-wrap: wrap;

  padding: 2rem 0 2rem 2rem;
}

footer .container .find-us .type {
  display: flex;
  flex-direction: column;

  color: var(--white);
}

footer .container .find-us .type .title {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: 2rem;
}

footer .container .find-us .type .title span {
  font-family: var(--font-title);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.05em;
}

footer .container .find-us .type .details span {
  font-family: var(--font-text);
  font-size: 1.25rem;
  font-weight: 400;
  display: block;
  margin-bottom: 0.5rem;
}

@media (max-width: 865px) {
  footer .container {
    grid-template-columns: 1fr;
  }

  footer .container .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
  }

  footer .container .find-us {
    padding: 2rem 0;
  }

  /* footer .container .find-us .type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
}

@media (max-width: 425px) {
  footer .container {
    padding: 0;
  }
}
