header {
  width: 100%;
  height: 80px;

  position: absolute;
  top: 0;
  left: 0;

  background: none;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;
}

header .container {
  max-width: 1080px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2rem;
}

header .container img {
  width: 250px;
}

header .container .small-image {
  width: 70px;
}

header .container button {
  width: 215px;
  height: 50px;

  background-color: var(--light-green);
  border-radius: 6px;

  color: var(--white);

  font-family: var(--font-title);
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -0.03em;

  border: none;
  outline: none;

  cursor: pointer;
}

@media (max-width: 375px) {
  header .container button {
    width: 180px;
  }
}

@media (max-width: 320px) {
  header .container button {
    width: 150px;
  }
}
