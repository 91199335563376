.whatsapp__contact {
  position: fixed;
  bottom: 10px;
  right: 10px;

  padding: 12px;
  border-radius: 100%;
  background-color: #25d366;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: 0.5s all;

  z-index: 9;
}

.scroll__to__top {
  position: fixed;
  bottom: 75px;
  right: 24px;

  padding: 10px;
  background-color: #373738;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: 0.5s all;

  z-index: 9;
}

.scroll__to__top img {
  width: 12px;
}

.whatsapp__contact img {
  width: 35px;
}

section.home {
  position: relative;

  width: 100%;

  background-image: url('../../assets/images/machine.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

section.home .over__green__layer {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(
    89.88deg,
    #00c661 -4.51%,
    rgba(9, 192, 99, 0.809816) 53.53%,
    rgba(47, 167, 106, 0) 126.6%
  );
}

section.home .container {
  position: relative;

  width: 100%;
  max-width: 1080px;

  padding: 160px 100px 100px 100px;
  margin: 0 auto;

  z-index: 11;
}

section.home .container h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 700;
  color: var(--white);

  margin-bottom: 3rem;
  z-index: 999;
  max-width: 587px;
}

section.home .container h5 {
  font-family: var(--font-text);
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--white);

  max-width: 600px;
  margin-bottom: 6rem;
}

section.home .container img {
  margin: 0 auto;

  display: block;

  cursor: pointer;
}

section.solutions .container {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  padding: 80px 0;
}

section.solutions .container .systems__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  column-gap: 12px;

  max-width: 460px;

  margin: 0 auto;
}

section.solutions .container .systems__icons .system {
  width: 220px;
  height: 105px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  row-gap: 0.5rem;
}

section.solutions .container .systems__icons .system#br_armazem {
  background-color: #39af8e;
}

section.solutions .container .systems__icons .system#br_corretora {
  background-color: #9a7aa8;
}

section.solutions .container .systems__icons .system#br_safra {
  background-color: #3687c8;
}

section.solutions .container .systems__icons .system#br_financas {
  background-color: #f0c263;
}

section.solutions .container .systems__icons .system#br_transporte {
  background-color: #e76162;
}

section.solutions .container .systems__icons .system h4 {
  font-family: var(--font-text);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--gray);
  display: inline-block;
}

section.solutions .container .systems__icons .system h4.white {
  color: var(--white);
}

section.solutions .container .know__more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  row-gap: 2rem;

  padding: 0 0 0 1rem;
}

section.solutions .container .know__more .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;
}

section.solutions .container .know__more .buttons button {
  background: none;
  border: none;

  color: #000;

  font-family: var(--font-text);
  font-size: 1.125rem;
  font-weight: 300;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  column-gap: 8px;
}

section.solutions .container .know__more h2 {
  font-family: var(--font-title);
  font-size: 3rem;
  line-height: 54px;

  font-weight: 600;
  text-align: left;

  max-width: 620px;
}

section.solutions .container .know__more h5 {
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 26px;
  font-weight: 400;
  text-align: left;
  color: var(--light-gray);

  max-width: 460px;
}

section.solutions .container .know__more a {
  width: 215px;
  height: 50px;

  background-color: var(--light-green);
  border-radius: 6px;

  color: var(--white);

  font-family: var(--font-text);
  font-size: 1.125rem;
  font-weight: 300;

  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  text-decoration: none;
}

section.about__us {
  background-color: #f7faf9;
}

section.about__us .container {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;

  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 3rem;

  padding: 80px 40px;
}

section.about__us .container .title h5 {
  font-family: var(--font-text);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 24px;
  color: #969696;
  letter-spacing: -0.05em;
  margin-bottom: 1rem;
}

section.about__us .container .title h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 600;
  line-height: 54px;
}

section.about__us .container .summary {
  font-family: var(--font-text);
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  color: var(--light-gray);

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
}

section.about__us .container .summary p {
  max-width: 400px;
}

section.statistics {
  background-color: var(--green);
}

section.statistics .container {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;

  padding: 80px 40px;
}

section.statistics .container .apresentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

section.statistics .container .apresentation h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 600;
  line-height: 54px;
  color: var(--white);
  margin-bottom: 4.5rem;
}

section.statistics .container .details {
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 6.3rem;
}

section.statistics .container .details .category {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;

  min-height: 150px;
}

section.statistics .container .apresentation .details .category .numbers {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 8px;

  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 700;
  line-height: 62px;

  color: var(--white);

  border-bottom: 2px solid var(--gray);

  margin-bottom: 8px;
}

section.statistics .container .apresentation .details .category .numbers h3 {
  font-family: var(--font-title-bold);
  font-size: 3rem;
  line-height: 62px;

  white-space: nowrap;
  color: var(--gray);
}

section.statistics .container .apresentation .details .category h5 {
  font-family: var(--font-text);
  font-size: 1.2rem;
  line-height: 24px;
  font-weight: 400;
  color: var(--white);
}

section.statistics .container .expansion {
  background-image: url('../../assets/images/map.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}

section.statistics .container .expansion img {
  width: 250px;
}

section.clients .container {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;

  padding: 80px 40px;

  position: relative;
}

section.clients .container h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 600;
  line-height: 54px;
  max-width: 440px;

  margin-bottom: 6rem;
}

section.clients .container img.appointment {
  position: absolute;

  right: 36px;
  top: 36px;
}

section.clients .container .slider {
  background: pr;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

section.clients .container .slider::before,
section.clients .container .slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

section.clients .container .slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

section.clients .container .slider::before {
  left: 0;
  top: 0;
}

section.clients .container .slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

section.clients .container .slider .slide {
  height: 100px;
  width: 250px;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

section.new__identity {
  background-color: #f8f8f8;
}

section.new__identity .container {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;

  padding: 80px 0;
}

section.new__identity .container h1 {
  font-family: var(--font-title);
  font-size: 3rem;
  font-weight: 600;
  text-align: center;

  margin-bottom: 4rem;
}

section.new__identity .container .history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: 3.5rem;
}

section.new__identity .container .history .stage {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

section.new__identity .container .history .stage .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 80px;
}

section.new__identity .container .history .stage .text h5 {
  font-family: var(--font-text);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.05em;
  color: #969696;
}

section.new__identity .container .history .stage .text h2 {
  font-family: var(--font-title);
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
  color: var(--gray);

  max-width: 580px;
}

section.new__identity .container .history .stage img {
  margin: 0 auto;

  width: 100%;

  padding: 2rem;
}

section.new__identity .container .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;

  padding: 5rem 0 0 0;
}

section.new__identity .container .buttons button {
  background: none;
  border: none;

  color: #000;

  font-family: var(--font-text);
  font-size: 1.125rem;
  font-weight: 300;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  column-gap: 8px;
}

section.new__identity .container .buttons a {
  width: 215px;
  height: 50px;

  background-color: var(--light-green);
  border-radius: 6px;

  color: var(--white);

  font-family: var(--font-text);
  font-size: 1.125rem;
  font-weight: 300;

  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  text-decoration: none;
}

@media (max-width: 1280px) {
  section.solutions .container .know__more {
    padding: 0 0 0 2rem;
  }

  section.solutions .container .know__more h2 {
    font-size: 2.8rem;
  }
}

@media (max-width: 1080px) {
  section.solutions .container .know__more h2 {
    font-size: 2.5rem;
  }

  section.solutions .container .systems__icons .system {
    width: 200px;
  }

  section.statistics .container .expansion {
    background-image: none;
  }

  section.new__identity .container .history .stage .text {
    padding: 0 20px 0 30px;
  }
}

@media (max-width: 856px) {
  section.solutions .container {
    grid-template-columns: 2fr 1fr;
  }

  section.solutions .container .systems__icons {
    row-gap: 0.5rem;
  }

  section.solutions .container .know__more h2 {
    line-height: 48px;
  }

  section.about__us .container {
    grid-template-columns: 1fr 1fr;
  }

  section.statistics .container {
    grid-template-columns: 1fr;
  }

  section.statistics .container .apresentation h1 {
    text-align: center;
  }

  section.statistics .container .expansion img {
    display: none;
  }

  section.new__identity .container h1 {
    max-width: 500px;

    margin: 0 auto 4rem auto;
  }

  section.new__identity .container .history .stage {
    grid-template-columns: 1fr;
  }

  section.new__identity .container .history .stage img {
    display: none;
  }

  section.new__identity .container .history .stage .text {
    padding: 0;
  }
}

@media (max-width: 768px) {
  section.home .container {
    padding: 160px 20px 100px 40px;
  }

  section.solutions .container {
    display: grid;
    grid-template-columns: 1fr;
  }

  section.solutions .container .systems__icons {
    display: none;
  }

  section.solutions .container .know__more {
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  section.solutions .container .know__more h2 {
    text-align: center;
    max-width: 400px;
  }

  section.solutions .container .know__more h5 {
    max-width: 380px;
    padding: 0;
    text-align: center;
  }

  section.about__us .container .title h1 {
    font-size: 2.5rem;

    line-height: 48px;
  }

  section.about__us .container .summary {
    justify-content: end;
  }

  section.clients .container .slider::before,
  section.clients .container .slider::after {
    background: linear-gradient(
      to right,
      white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100px;
    position: absolute;
    width: 100px;
    z-index: 2;
  }

  section.clients .container .slider .slide-track {
    -webkit-animation: scroll 20s linear infinite;
    animation: scroll 20s linear infinite;
  }

  section.clients .container .slider .slide {
    width: 200px;
  }
}

@media (max-width: 568px) {
  section.statistics .container .details {
    column-gap: 2rem;
  }
}

@media (max-width: 624px) {
  section.about__us .container {
    grid-template-columns: 1fr;
  }

  section.about__us .container .title {
    margin-bottom: 3rem;
  }

  section.new__identity .container .history .stage {
    padding: 0 40px;
  }
}

@media (max-width: 425px) {
  section.solutions .container .know__more h5 {
    padding: 1.5rem;
  }

  section.statistics .container {
    padding: 80px 10px;
  }

  section.statistics .container h1 {
    line-height: 48px;

    padding: 0 2rem;
  }

  section.statistics .container .details {
    display: flex;
    flex-wrap: wrap;
  }

  section.statistics .container .details {
    row-gap: 2rem;
  }

  section.statistics .container .apresentation .details .category .numbers {
    width: 100%;
  }

  section.statistics .container .apresentation .details .category h5 {
    text-align: center;
    max-width: 200px;
  }
}

@media (max-width: 375px) {
  section.home .container {
    padding: 160px 20px 100px 20px;
  }

  h1 {
    line-height: 38px;
  }
}

@media (max-width: 320px) {
  section.solutions .container .know__more h2 {
    padding: 0 20px;
  }

  section.solutions .container .know__more .buttons {
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  section.new__identity .container h1 {
    padding: 0 20px;
  }

  section.new__identity .container .buttons {
    flex-wrap: wrap;
    row-gap: 2rem;
  }
}
